export default function AjaxProduct() {
	function processAjax(pageUrl) {
		$.ajax({
			url: pageUrl,
			data: {
				isajax: false,
			},
			beforeSend: function (data) {
				$(".section-product-list .wrap-product-main").addClass("loading-ajax");
			},
			success: async function (data) {
				const countProduct = $(data).find('.item-product-main').length
				console.log("🚀 ~ file: ajax.js:13 ~ countProduct", countProduct)
				$('.wrap-product-main').html($(data).find('.wrap-product-main').html())
				$('.wrap-left').html($(data).find('.wrap-left').html())
				$('.wrap-right').html($(data).find('.wrap-right').html())
				if ($(data).find('.modulepager').length == 1) {
					$('.productpager .modulepager').html($(data).find('.modulepager').html())
				} else {
					$('.productpager .modulepager').html('')
				}
				if (countProduct == 0) {
					$('.wrap-product-main').append('<div class="no-products">Sản phẩm đang được cập nhật</div>')
				}
				lozad().observe();
			},
			complete: function (data) {
				setTimeout(() => {
					$(".section-product-list .wrap-product-main").removeClass("loading-ajax");
				}, 500);
			},
		});
		if (pageUrl != window.location) {
			window.history.pushState({ path: pageUrl }, "", pageUrl);
		}
	}
	$("body").on("click", ".ajaxlink", function () {
		let pageUrl = $(this).attr("href");
		processAjax(pageUrl);
		return false;
	});
}

