/*==================== Header ====================*/
/**
 * @param header
 */
export const header = {
	paddingBottom: function () {
		let heightHeader = $("header").outerHeight();
		$("main").css("padding-top", heightHeader);
	},
	appendDropdownMenu: function () {
		$('.header-main-menu .has-child a').each(function () {

		})
	},
	handleClickHambuger: function () {
		$(".header-hambuger").on("click", function () {
			$(this).toggleClass("active");
			$("header .wrap-list-menu").toggleClass("active");
		});
	},
	scrollActive: function () {
		let height = $("header").height();
		if ($(window).scrollTop() > height) {
			$("header").addClass("active");
		} else {
			$("header").removeClass("active");
		}
	},
	toggleMenu: function () {
		if ($(window).width() > 1024) return;
		$(".header-main-menu li.menu-item-has-children>a").on('click', function(e){
			e.preventDefault();
			$(this).toggleClass('active').next().slideToggle().closest('li').siblings().find('a').removeClass('active').next().slideUp();
			return false
		});
	},
	init: function () {
		header.scrollActive();
		header.handleClickHambuger();
		header.paddingBottom();
		header.toggleMenu();
	},
};
document.addEventListener(
	"scroll",
	function (e) {
		header.scrollActive();
	},
	true
);



