export default function headerSearchForm() {
	function closeSearch() {
		$(".header-search-form").removeClass("active");
		$("body").removeClass("disable");
	}
	$(".header-search").on("click", function () {
		$(".header-search-form").addClass("active");
		$("body").addClass("disable");
		setTimeout(() => {
			$(".header-search-form .searchinput").focus();
		}, 400);
	});
	$(".header-search-form .close").on("click", function () {
		closeSearch();
	});
	$(document).keyup(function (e) {
		if (e.key === "Escape") {
			closeSearch();
		}
	});
	$(document).on("click", function (e) {
		var container = $(".productsearchbox");
		var buttonSearch = $(".header-search");
		if (!container.is(e.target) && container.has(e.target).length === 0 && $(e.target).closest(buttonSearch).length === 0) {
			$(".header-search-form").removeClass("active");
		}
	});
}

