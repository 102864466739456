import Swiper, { Autoplay, EffectFade, Navigation, Pagination, Thumbs } from "swiper";
/**
 * @param swiperInit
 */
export default function swiperInit() {
	var SectionSlideHome = new Swiper(".section-home-1 .swiper", {
		spaceBetween: 0,
		speed: 1000,
		slidesPerView: 1,
		modules: [Pagination, Navigation, EffectFade, Autoplay],
		effect: "fade",
		autoplay: {
			delay: 3000,
		},
		speed: 1000,
		pagination: {
			el: ".section-home-1 .swiper-pagination",
			clickable: true,
		},
	});

	var SectionSlideHome5 = new Swiper(".section-brand-hot .swiper", {
		spaceBetween: 8,
		speed: 1000,
		slidesPerView: 2,
		modules: [Navigation],
		autoplay: {
			delay: 5000,
		},
		speed: 1000,
		breakpoints: {
			575: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 3,
			},
			1315: {
				slidesPerView: 5,
			},
		},
		navigation: {
			nextEl: ".section-brand-hot .btn-next",
			prevEl: ".section-brand-hot .btn-prev",
		},
	});
	var SectionSlideHome5 = new Swiper(".section-about-4 .swiper", {
		spaceBetween: 32,
		speed: 1000,
		slidesPerView: 1,
		modules: [Navigation],
		autoplay: {
			delay: 5000,
		},
		speed: 1000,
		navigation: {
			nextEl: ".section-about-4 .btn-next",
			prevEl: ".section-about-4 .btn-prev",
		},
	});
	var ProductDetailImgThumb = new Swiper(
		".product-detail-main .img-product-thumb .swiper",
		{
			slidesPerView: 3,
			spaceBetween: 10,
			autoplay: {
				delay: 1500,
			},

			breakpoints: {
				575: {
					spaceBetween: 20,
					slidesPerView: 3,
					direction: "vertical",
				},
				1280: {
					spaceBetween: 20,
					direction: "vertical",
					slidesPerView: 4,
				},
			},
		}
	);
	var ProductDetailImgMain = new Swiper(
		".product-detail-main .img-product-main .swiper",
		{
			spaceBetween: 10,
			speed: 500,
			modules: [Thumbs, Navigation],
			slidesPerView: 1,
			freeMode: true,
			watchSlidesProgress: true,
			speed: 300,
			navigation: {
				nextEl: ".product-detail-main .img-product-thumb .btn-next",
				prevEl: ".product-detail-main .img-product-thumb .btn-prev",
			},
			thumbs: {
				swiper: ProductDetailImgThumb,
			},
		}
	);
	var ProductRelatedMain = new Swiper(".section-product-related .swiper", {
		slidesPerView: 1,
		spaceBetween: 10,
		autoplay: {
			delay: 1500,
		},
		modules: [Navigation],
		speed: 300,
		navigation: {
			nextEl: ".section-product-related .btn-next",
			prevEl: ".section-product-related .btn-prev",
		},
		breakpoints: {
			575: {
				spaceBetween: 30,
				slidesPerView: 2,
			},
			1280: {
				spaceBetween: 30,
				slidesPerView: 3,
			},
		},
	});
	var ProductOption2_thumb = new Swiper(".section-product-option-2.section-2 .img-thumb .swiper", {
		slidesPerView: 3,
		spaceBetween: 10,
		autoplay: {
			delay: 1500,
		},
		speed: 300,
		freeMode: true,
		watchSlidesProgress: true,
		breakpoints: {
			575: {
				spaceBetween: 15,
				slidesPerView: 3,
			},
			1024: {
				spaceBetween: 15,
				slidesPerView: 4,
			},
		},
	});
	var ProductOption2_main = new Swiper(
		".section-product-option-2.section-2 .img-main .swiper",
		{
			spaceBetween: 10,
			speed: 500,
			slidesPerView: 1,
			modules: [Thumbs, Navigation],
			thumbs: {
				swiper: ProductOption2_thumb,
			},
			navigation: {
				nextEl: ".section-product-option-2.section-2 .img-thumb  .btn-next",
				prevEl: ".section-product-option-2.section-2 .img-thumb  .btn-prev",
			},
		}
	);

}

