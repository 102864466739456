import { CountUp } from "countup.js";
import { header } from "./header";
import swiperInit from "./swiperInit";
import headerSearchForm from "../components/common/SearchForm/SearchForm";

import {
	setBackgroundElement,
	ButtonToTop,
	ToggleItem,
	funcExpandContent,
} from "./utils";
import AjaxProduct from "./ajax";

function ButtonTableExpand() {
	let heightTD = $(".section-refresher-training tr td").outerHeight();
	let headerTD = $(".section-refresher-training thead").outerHeight();
	console.log(
		"🚀 ~ file: main.js ~ line 13 ~ ButtonTableExpand ~ headerTD",
		headerTD
	);
	let number = $(".section-refresher-training tr td").length;
	let heightMin = heightTD * 10 + headerTD;
	$(".section-refresher-training .wrap-table-responsive").css(
		"height",
		heightMin
	);
	$(".section-refresher-training").each(function () {
		let number = $(this).find("tbody tr").length;
		if (number <= 10) {
			$(this).find(".button-see-more").remove();
		}
		console.log("🚀 ~ file: main.js ~ line 22 ~ number", number);
	});
	$(".section-refresher-training .button-see-more").on("click", function () {
		let heightWrap = $(this)
			.closest(".container")
			.find(".wrap")
			.outerHeight();
		$(this).toggleClass("active");
		if ($(this).hasClass("expand")) {
			$(this)
				.closest(".container")
				.find(".wrap-table-responsive")
				.css("height", heightMin);
			$(this).find("span").text("Xem thêm");
		} else {
			$(this)
				.closest(".container")
				.find(".wrap-table-responsive")
				.css("height", heightWrap);
			$(this).find("span").text("Rút gọn");
		}
		$(this).toggleClass("expand");
	});
}

function countUpHome2() {
	const nodeEle = document.querySelectorAll(".number");
	nodeEle.forEach((item) => {
		var value = item.textContent;
		var countUp = new CountUp(item, value, {
			enableScrollSpy: true,
		});
		countUp.start();
	});
}

function itemHoverHome3() {
	$(".section-home-3 .item-category-main").hover(function () {
		let value = $(this).data("image");
		console.log("🚀 ~ file: main.js:70 ~ value", value);
		$(`.section-home-3 .img img[src='${value}']`)
			.addClass("active")
			.siblings()
			.removeClass("active");
	});
}

function clickFilterProduct() {
	$(".wrap-filter-button").on("click", function () {
		$("html, body").animate({ scrollTop: 0 });
		$(".wrap-select-filter").toggleClass("active");
	});
	$(".wrap-select-filter .close").on("click", function () {
		$(".wrap-select-filter").removeClass("active");
	});
	$('.wrap-select-filter').append('<div class="md:hidden block w-full"><div class="btn btn-apply-filter">Lọc</div></div>')
	$('.btn-apply-filter').on('click', function () {
		$(".wrap-select-filter").removeClass("active");

	})
}
function clickScrollDownSection() {
	$(".btn-scroll-down").on("click", function () {
		$("html, body").animate({
			scrollTop:
				$(".section-home-2").offset().top - $("header").outerHeight(),
		});
	});
}

function appendProductMain() {
	if (!$(".section-banner-product").length) return;
	$(".global-breadcrumb").appendTo(".section-banner-product");
	$(".wrap-form-product").appendTo(".popup-contact");
}
function cmsAdminPannel() {
	$(".cmsadminpanel .nav:not(.listmenu)").on("click", function () {
		$(this).closest(".cmsadminpanel").toggleClass("active");
		$(".cmsadminpanel .listmenu").toggleClass("active");
	});
}



$(document).ready(function ($) {
	cmsAdminPannel()
	// Home
	clickScrollDownSection();
	itemHoverHome3();
	// Product
	AjaxProduct();
	appendProductMain();
	// Other
	swiperInit();
	header.init();
	countUpHome2();
	headerSearchForm();
	setBackgroundElement();
	ButtonToTop();
	ToggleItem();
	clickFilterProduct();
	AOS.init({
		offset: 120,
		startEvent: "DOMContentLoaded", // name of the event dispatched on the document,
		delay: 300, // values from 0 to 3000, with step 50ms
	});
	Fancybox.defaults.parentEl = document.getElementById('aspnetForm');
});

/*==================== Lazyload JS ====================*/
const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();

