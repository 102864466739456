export function setBackgroundElement() {
	$("[setBackground]").each(function () {
		var background = $(this).attr("setBackground");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-size": "cover",
			"background-position": "center center",
		});
	});
}
export function ButtonToTop() {
	let windowHeight = $(window).height();
	$(document).on("scroll", function () {
		let scrollTop = $(window).scrollTop();
		let documentHeight = $(document).height();
		if (scrollTop + windowHeight > documentHeight - windowHeight) {
			$(".button-to-top").addClass("active");
		} else {
			$(".button-to-top").removeClass("active");
		}
	});
	$(document).on("click", ".button-to-top", function () {
		$("html, body").animate({ scrollTop: 0 });
	});
}

export function ToggleItem() {
	const nodeParent = $(".wrap-item-toggle");
	const nodeItem = nodeParent.find(".item-toggle");
	const nodeTitle = nodeItem.find(".title");
	nodeTitle.on("click", function () {
		$(this)
			.toggleClass("active")
			.next()
			.slideToggle()
			.closest(".item-toggle")
			.siblings()
			.find(".title")
			.removeClass("active")
			.next()
			.slideUp();
	});
}

/**
 * parent, children, item, button, initItem
 * @param { parent, children, item, button, initItem} listNode
 */
export function funcExpandContent(listNode) {
	const { parent, children, item, button, initItem } = listNode;
	let itemHeight = $(item).outerHeight();
	let initHeight = itemHeight * initItem;
	let originalHeight = $(parent).find(children).outerHeight();
	$(parent).css("height", initHeight);
	$(button).on("click", function () {
		if ($(this).hasClass("expand")) {
			$(parent).css("height", initHeight);
			$(this).find("span").text("Xem thêm");
		} else {
			$(parent).css("height", originalHeight);
			setTimeout(() => {
				$(parent).css("height", "auto");
			}, 1000);
			$(this).find("span").text("Rút gọn");
		}
		$(this).toggleClass("expand");
	});
}




